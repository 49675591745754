<template>
  <div style="width: 100%;display: flex">
    <el-upload action="/business/upload" :list-type="pictureCard"
               :file-list="_value"
               :before-upload="beforeUpload"
               :http-request="uploadFile"
               ref="upload"
               :limit="limit"
               :on-exceed="overFile"
               :multiple="true">
      <i slot="default"  class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}" class="file-list">

        <img alt="" title="视频,单击播放"
             :src="file.url+'?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto'"
             v-if="includeVideoFile(file.url)">
        <img v-else class="el-upload-list__item-thumbnail" title="单击预览" :src="file.url" alt="加载中">
        <span class="el-upload-list__item-actions">
           <span class="el-upload-list__item-preview" @click="handlePreview(file,includeVideoFile(file.url))">
             <i class="el-icon-zoom-in"></i>
           </span>
           <span class="el-upload-list__item-delete"  @click="handleDelete(file)">
             <i class="el-icon-delete"></i>
           </span>
          </span>
      </div>
    </el-upload>

    <div class="video-wrapper" @click="closeVideo" v-if="viewImages.length&&includeVideoFile(viewImages[0])">
      <video id="video-play" class="video" autoplay controls="controls">
        <source :src="viewImages[0]" :type="'video/'+viewImages[0].substring(viewImages[0].lastIndexOf('.')+1)">
      </video>
    </div>

    <viewer v-if="viewImages.length" ref="previewFileRef" class="file-content" :images="viewImages">
      <img v-for="(src,index)  in viewImages" :key="src" width="100%" :src="src" alt="">
    </viewer>
  </div>

</template>

<script>
import uploadCommon from "@/utils/upload";
import {deleteFIle} from "@/services/check";
export default {
  name: "d-upload",
  props:{
    fileList:{
      type:Array,
      default(){
        return []
      }
    },
    pictureCard:{
      type:String,
      default(){
        return 'picture-card'
      }
    },
    limit:{
      type:Number,
      default(){
        return 5
      }
    },
    multiplePreview:{
      type:Boolean,
      default(){
        return false
      }
    }

  },
  computed:{
    limitFileNum(){
      return this.limit-this._value.length
    },
    _value:{
      get(){
        return this.fileList
      },
      set(val){
        this.$emit("update:fileList",val)
      }
    }
  },
  data(){
    return {
      showPreview:false,
      viewImages:[]
    }
  },
  methods:{
    uploadFile(fileData){
      const file = fileData.file;
      //上传给后端，拿到url
      let form = new FormData;
      form.append("file",file);
      const that = this;
      this.$api.sysapi.uploadFile(form,'tour')
          .then(({code, data, msg})=>{
            if(code === 200){
              that._value.push({
                url: data
              })
            }
          })
    },
    beforeUpload(file){
      if(this._value.length<this.limit){
        //验证文件格式和大小最大100M
        return uploadCommon.checkFileAndSize(file,100,['jpg','png','jpeg','mp4','mov','m4v','3gp','m3u8','webm']);
      }else{
        this.$message.error(`最多上传${this.limit}个文件`);
        return false;
      }
    },

    overFile(files,fileList){
      if(files.length<=(this.limit-this._value.length)){
        return true
      }else{
        this.$message.warning(`最多可上传${this.limit}个附件,当前最多可选择 ${this.limit-this._value.length} 个附件`);
        this.$refs.upload.fileList = this._value
        return false
      }
    },

    handlePreview(data,isVedio){
      let currentIndex = 0;
      if(isVedio||!this.multiplePreview){
        this.viewImages = [data.url]
      }else{
        this.viewImages = this._value.filter(e=>uploadCommon.isCommonPicture(e)).map(e=>e.url || e.fileUrl);
        currentIndex = this.viewImages.findIndex(e=>e==data.url)
      }
      this.$nextTick(()=>{
        !isVedio && (this.$refs.previewFileRef.$viewer.view(currentIndex));
      })
    },

    handleDelete(file){
      const that = this;
      return new Promise((resolve,reject)=>{
        let fileUrl = file.url;
        deleteFIle({
          fileUrl:fileUrl,
        }).then(res=>{
          that._value = that._value.filter(e=>e.url!==file.url) || []
          resolve()
        })
      })
    },

    includeVideoFile(url){
      if(!url) return
      let fileType =  url.substring(url.lastIndexOf('.'))
      let videoType =  ['.mp4','.mov','.m4v','.3gp','.m3u8','.webm'];
      return videoType.includes(fileType)
    },

    closeVideo(e){
      let videoDom = document.getElementById('video-play')
      if(!videoDom.contains(e.target)){
        this.viewImages = [];
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-upload--picture-card,
::v-deep .el-upload-list__item{
  width: 60px;
  height: 60px;
  line-height: 66px;
}
::v-deep  .entry-card-img .el-form-item__error{
  margin-left: 20px;
}
.file-list{
  cursor: pointer;
}
.file-content img{
  display: none;
}
.video-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 99;
  background: rgba(0,0,0,0.45);
}
.video{
  z-index: 100;
  -o-object-fit: fill;
  object-fit: cover;
  height: 540px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>