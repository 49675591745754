var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add"},[_c('el-form',{ref:"form",staticStyle:{"width":"80%"},attrs:{"inline":false,"model":_vm.form,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"店铺","prop":"shopIds","rules":_vm.selectRules}},[_c('el-cascader',{ref:"cascader",attrs:{"options":_vm.shopOptions,"props":{
                      value: 'id',
                      label: 'name',
                      children: 'childList'
                   },"size":"small","filterable":"","clearable":""},model:{value:(_vm.form.shopIds),callback:function ($$v) {_vm.$set(_vm.form, "shopIds", $$v)},expression:"form.shopIds"}})],1),_c('el-form-item',{attrs:{"label":"罚金理由","prop":"reason","rules":_vm.inputRules}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入","maxlength":"50","show-word-limit":""},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}})],1),_c('el-form-item',{attrs:{"label":"罚金类型","prop":"penalTypes","rules":_vm.selectRules}},[_c('el-cascader',{attrs:{"options":_vm.ticketTypes,"props":{
          value: 'id',
          label: 'typeName',
          children: 'options',
          multiple: true
        },"size":"small","filterable":"","collapse-tags":"","clearable":""},on:{"change":_vm.changeTicketFun},model:{value:(_vm.form.penalTypes),callback:function ($$v) {_vm.$set(_vm.form, "penalTypes", $$v)},expression:"form.penalTypes"}})],1),_c('el-form-item',{attrs:{"label":"详情"}},[_c('TicketDetail',{attrs:{"options":_vm.penalTypes,"total":_vm.penalTotal + '元'}})],1),_c('el-form-item',{attrs:{"label":"最晚处理时间","prop":"estimateTime","rules":_vm.inputRules}},[_c('el-date-picker',{attrs:{"picker-options":{
          disabledDate: (time) => {
            return time.getTime() < Date.now() - 24*3600*1000
          }
        },"size":"small","value-format":"yyyy-MM-dd","type":"date","placeholder":"选择日期"},model:{value:(_vm.form.estimateTime),callback:function ($$v) {_vm.$set(_vm.form, "estimateTime", $$v)},expression:"form.estimateTime"}})],1),_c('el-form-item',{attrs:{"label":"备注"}},[_c('el-input',{attrs:{"type":"textarea","size":"small","rows":4,"maxlength":"100","show-word-limit":"","placeholder":"请输入"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1),_c('el-form-item',{attrs:{"label":"上传图片/视频","prop":"resource","rules":_vm.uploadRules}},[_c('d-upload',{attrs:{"file-list":_vm.form.resource,"limit":10,"multiple-preview":true},on:{"update:fileList":function($event){return _vm.$set(_vm.form, "resource", $event)},"update:file-list":function($event){return _vm.$set(_vm.form, "resource", $event)}}})],1)],1),_c('div',{staticClass:"footer"},[_c('el-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.submitFun}},[_vm._v("提交")]),_c('el-button',{attrs:{"type":"info","size":"large"},on:{"click":_vm.cancelFun}},[_vm._v("取消")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }